<template>
  <BaseDialog
    ref="baseDialog"
    width="500px"
    :dialogVisible.sync="dialog"
    :title="title"
  >
    <template>
<!--      <AuthorizeInfo :data="data" />-->
      <BaseForm
        v-loading="loading"
        ref="form"
        label-width="100px"
        :cols="formField"
        :form="form"
        :formVisible="dialog"
      >
      </BaseForm>
      <el-button
        type="primary"
        :loading="testLoading"
        element-loading-spinner="el-icon-loading"
        style="margin-left: 25px"
        size="mini"
        plain
        @click="autoTest"
        v-if="testBtn"
        :disabled="!isDisabled"
      >
        授权状态测试
      </el-button>
    </template>
    <template #footer>
      <LoadingBtn type="primary" @click="doSubmit" :isDisabled="isDisabled"> 确认 </LoadingBtn>
      <LoadingBtn @click="cancel"> 取消 </LoadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import AuthorizeInfo from './authorizeInfo'

import { normalFile, emsFile, yotoFile, disifangFile } from '@/views/fedex/module/authorize/field'
import { POST_OFFICE, YOTO, DI_SIFANG } from '@/views/fedex/module/authorize/companyCode'
import { testAuth, updateAuth } from '@/api/expressAccount'
import { commonFromMixin } from '@/mixins'
import { getValueFromObj } from '@/utils'
import { deepClone } from '@/components/avue/utils/util'

export default {
  mixins: [commonFromMixin],
  components: {
    AuthorizeInfo
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    afterSubmit: Function
  },
  data() {
    return {
      form: {},
      
      testBtn: true,
      isDisabled: true,
      testLoading: false,
    }
  },
  computed: {
    formField({ data }) {
      let companyCode = getValueFromObj(data, '$parentRow.companyCode')
      switch (companyCode) {
        case POST_OFFICE:
          return emsFile
        case YOTO:
          return yotoFile
        case DI_SIFANG:
          this.testBtn = false;
          this.isDisabled = false;
          return disifangFile
        default:
          return normalFile
      }
    },
  },
  watch: {
    data: {
      handler() {
        this.form.id = this.data.id
        this.formField.map(({prop}) => {
          this.$set(this.form, prop, this.data[prop])
        })
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    async doSubmit() {
      const valid = await this.$refs['form'].validate()
      if (!valid) return

      this.loading = true
      try {
        const api = updateAuth
        const res = await api(this.form)
        let isSuccess = $SUC(res)
        await this.handleAfterSubmit(isSuccess)
        if (isSuccess) {
          this.dialog = false
        }
      } catch (err) {
        console.log('err', err)
      }
      this.loading = false
    },
    async handleAfterSubmit(isSuccess) {
      if (typeof this.afterSubmit === 'function') return await this.afterSubmit(isSuccess)
      if (isSuccess) {
        let { companyId } = this.data
        this.$message.success('操作成功')
        return await this.sup_this.getAccountList(this.sup_this.tableData.find(company => company.id === companyId))
      }
    },
    
    async autoTest() {
      try {
        const valid = await this.$refs["form"].validate();
        if (!valid) return;
        this.loading = true;
        this.testLoading = true;
        const testForm = deepClone(this.form);
        // 邮政 云途
        // delete testForm.accountName;
        testForm.companyId = this.data.companyId;
        const { detail: data } = await testAuth(testForm);
        if (data) {
          this.isDisabled = false;
          this.showLoading();
          this.$message.success("检测成功");
        } else {
          this.showLoading();
          this.$message.error("检测失败");
        }
      } catch (e) {
        console.log(e);
        this.showLoading();
      }
    },
    showLoading() {
      this.loading = false;
      this.testLoading = false;
    }
  }
}
</script>
