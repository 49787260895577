<template>
  <div class="authorize-info-component">
    <div class="row">
      <div class="r-item" :key="index" v-for="(value, key, index) in info">
        <span class="label"> {{ key }}: </span>
        <span class="name">
          {{ value }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { changeObjKey } from '@/utils/common'

const KEYS_CONVERSION = {
  客戶编码: 'customerCode',
  ApiSecret: 'clientSecret',
  clientId: 'clientId'
}
const KEYS_CONVERSION1 = {
  揽件编号: 'whCode',
  密钥: 'clientSecret',
  大客户代码: 'clientId'
}
const KEYS_CONVERSION2 = {
  密钥: 'clientSecret',
  客户编码: 'clientId'
}
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    info() {
      // console.log(this.data);
      if(this.data.companyId == 3) {
        // 邮政
        return changeObjKey(this.data, KEYS_CONVERSION1)
      } else if(this.data.companyId == 4){
        return changeObjKey(this.data, KEYS_CONVERSION2)
      } else {
        return changeObjKey(this.data, KEYS_CONVERSION)
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
.authorize-info-component {
  .row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    background: $bg-color;
    padding: 0 10px;
    .r-item {
      padding: 5px 10px;
    }
    .label {
      color: #000;
    }
    .name {
      color: $color-gray;
    }
  }
}
</style>
